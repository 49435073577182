@import '../base/variables';

// Specific Breakpoints
@mixin xsMaxBreakpoint {
    @media only screen and (max-width: #{$max-screen-sm}) {
        @content;
    }
}

@mixin smLayoutBreakpoint {
    @media only screen and (min-width: #{$min-screen-sm}) and (max-width: #{$max-screen-sm}) {
        @content;
    }
}

@mixin mdLayoutBreakpoint {
    @media only screen and (min-width: #{$min-screen-md}) and (max-width: #{$max-screen-md}) {
        @content;
    }

}

@mixin lgLayoutBreakpoint {
    @media only screen and (min-width: #{$min-screen-lg}) and (max-width: #{$max-screen-lg}) {
        @content;
    }
}

@mixin xlLayoutBreakpoint {
    @media only screen and (min-width: #{$min-screen-xl}) and (max-width: #{$max-screen-xl}) {
        @content;
    }
}

@mixin x2LayoutBreakpoint {
    @media only screen and (min-width: #{$min-screen-2x}) and (max-width: #{$max-screen-2x}) {
        @content;
    }
}

@mixin x3LayoutBreakpoint {
    @media only screen and (min-width: #{$min-screen-3x}) and (max-width: #{$max-screen-3x}) {
        @content;
    }
}

@mixin x4LayoutBreakpoint {
    @media only screen and (min-width: #{$min-screen-4x}) and (max-width: #{$max-screen-4x}) {
        @content;
    }
}

// Min Breakpoints
@mixin smBreakpoint {
    @media only screen and (min-width: #{$min-screen-sm}) {
        @content;
    }
}

@mixin mdBreakpoint {
    @media only screen and (min-width: #{$min-screen-md}) {
        @content;
    }

}

@mixin lgBreakpoint {
    @media only screen and (min-width: #{$min-screen-lg}) {
        @content;
    }
}

@mixin xlBreakpoint {
    @media only screen and (min-width: #{$min-screen-xl}) {
        @content;
    }
}

@mixin x2Breakpoint {
    @media only screen and (min-width: #{$min-screen-2x}) {
        @content;
    }
}

@mixin x3Breakpoint {
    @media only screen and (min-width: #{$min-screen-3x}) {
        @content;
    }
}

@mixin x4Breakpoint {
    @media only screen and (min-width: #{$min-screen-4x}) {
        @content;
    }
}

// Mobile Breakpoints
@mixin extraSmMobileBreakpoint {
    @media only screen and (max-width: #{$max-mobile-xs}) {
        @content;
    }
}

@mixin miniSmMobileBreakpoint {
    @media only screen and (max-width: #{$max-mobile-mini}) {
        @content;
    }
}

@mixin smMobileBreakpoint {
    @media only screen and (max-width: #{$max-mobile-sm}) {
        @content;
    }
}

// Custom Breakpoints
@mixin customMinBreakpoint($size) {
    @media (min-width: #{$size + 'px'}) {
        @content;
    }
}

@mixin customMaxBreakpoint($size) {
    @media (max-width: #{$size + 'px'}) {
        @content;
    }
}