.ant-tag {
    @apply px-2.5 font-medium;

    &-draft,
    &-public {
        @apply text-gray-600 bg-gray-200;
    }

    &-published,
    &-success {
        color: $green700;
        background-color: $green100;
    }

    &-closed {
        @apply text-sky-700 bg-cyan-100;
    }

    &-warning {
        @apply text-yellow-600 bg-yellow-100;
    }

    &-error {
        @apply text-red-700 bg-red-100;
    }

    &-info {
        @apply text-indigo-600 bg-indigo-100;

        &-ghost {
            @apply bg-white border-indigo-300 text-indigo-700
        }
    }

    &-default {
        @apply bg-white text-gray-500;
    }

    &-secondary {
        @apply text-gray-400 bg-gray-200 border-gray-200 font-normal;

        &:hover,
        &.active {
            @apply text-gray-700 bg-gray-300;
        }
    }

    &-secondary-dark {
        @apply text-gray-400 bg-gray-300 border-gray-300 font-normal;

        &:hover,
        &.active {
            @apply text-gray-700;
        }
    }

    &.tag-md {
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;
    }

    &.tag-lg {
        padding-top: 0.3875rem;
        padding-bottom: 0.3875rem;
    }

    &.tag-xl {
        @apply rounded-md text-sm;
        padding: 0.625rem;
    }
}

ul {
    list-style: disc;
    @apply pl-6 text-gray-600 text-base font-normal;

    li {
        margin-bottom: .25rem;
    }
}

ol {
    list-style: decimal;
    @apply pl-6 text-gray-600 text-base font-normal;

    li {
        margin-bottom: 0.25rem;
    }

    li::marker {
        @apply text-gray-600 opacity-100 font-normal;
    }

}

.ant-popover-inner {
    border-radius: 0.8rem !important;
}