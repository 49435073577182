.ant-list-split {
    .ant-list-item,
    .ant-list-header {
        border-block-end-color: $neutral200;
    }
}

.ant-list {
    .ant-list-item {
        .ant-list-item-meta-content {
            .ant-list-item-meta-title {
                margin-bottom: 0;
            }
        }
    }
}

.text-gray-600 {
    .ant-list-item-meta-content {
        .ant-list-item-meta-title {
            span {
                color: #4B5563;
                font-size: 1rem;
            }
        }
    }
}

.text-sm {
    .ant-list-item-meta-content {
        .ant-list-item-meta-title {
            span {
                font-size: 0.875rem;
                line-height: 1.25rem;
            }
        }
    }
}

.font-semibold {
    .ant-list-item-meta-content {
        .ant-list-item-meta-title {
            span {
                font-weight: 600;
            }
        }
    }
}

.border-0 {
    .ant-spin-nested-loading {
        .ant-spin-container {
            .ant-list-items {
                .ant-list-item {
                    border: 0;
                }
            }
        }
    }
}

.ant-list-empty-text {
    padding: 0 !important;
}

.h-min {
    .ant-empty-image {
        height: 3.125rem;
    }

    .ant-empty-description {
        span {
            font-size: medium;
            font-weight: 400;
        }
    }
}