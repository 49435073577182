.border-neutral-500 {
    border-color: $neutral500;
}

.text-light-100 {
    color: $light100;
}

.bg-light-100 {
    background-color: $light100;
}