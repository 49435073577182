@import './base/variables';

body {
    background-color: $light100;
}

.bg-body {
    background-color: $light100;
}

.App {
    &:has(.status-action-bar) {
        padding-bottom: 6rem;
    }

    &:has(.compact-footer) {
        padding-bottom: 7rem;
    }

    &:has(.status-action-bar):has(.compact-footer) {
        padding-bottom: 10rem;

        .compact-footer {
            bottom: 7rem;
        }
    }
}

.rpv-core__inner-page,
.rpv-default-layout__toolbar {
    background-color: #f6f6f9 !important;
}

.generateForm {
    .ant-form-item-explain:has(img) {
        margin: 0;
    }
}

.currency-input {
    position: relative;
    display: flex;
    align-items: center;

    // .currency-sign {
    //     @apply absolute left-[.7rem] hidden;
    // }

    input:not(:placeholder-shown) {
        margin-left: .7rem;
    }

    // &:has(input:not(:placeholder-shown)) .currency-sign {
    //     @apply inline;
    // }
}

.input-number {
    display: flex;
    align-items: center;

    .ant-form-item {
        display: inline-block;
        width: 100%;
    }

    .input-number-suffix {
        display: none;
    }

    input:not(:placeholder-shown) {
        text-align: center;
    }

    &:has(input:not(:placeholder-shown)) {
        .ant-form-item {
            width: 2rem;
        }

        .input-number-suffix {
            display: inline-block;
        }
    }
}

::marker {
    font-size: 0.8em;
    font-weight: lighter;
}

.ant-list-header {
    padding: 0;
    padding-block: 1.25rem 0 1.25rem;
}

.ant-list-item {
    padding: 0 !important;
}

.disabled {
    opacity: 0.9;
}

.ant-spin-nested-loading {
    width: 100%;
}

.ant-list-item-action {
    margin-inline-start: 0 !important;
    padding: 0;
}

.ant-list .ant-list-header {
    padding-block: 0
}

// Scrollbar style
/* width */
::-webkit-scrollbar {
    width: 0.35rem;
    height: 0.35rem;
}


/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $gray300;
    border-radius: 0.2rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $gray600;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.ant-notification-notice-close {
    display: none !important;
    pointer-events: none;
}