@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    button,
    [type='button'],
    [type='reset'],
    [type='submit'] {
        -webkit-appearance: button;
        background-color: transparent;
        background-image: none;
    }
}

@layer components {
    .overflow-wrap-word {
        overflow-wrap: break-word;
    }
}

@import './base/variables';
@import './base/typography';

@import './mixins/breakpoints';

@import './animations/loader';
@import './animations/typography';
@import './animations/effects';

@import './librariesOverrides/antdOverrides/layout';
@import './librariesOverrides/antdOverrides/alert';
@import './librariesOverrides/antdOverrides/notification';
@import './librariesOverrides/antdOverrides/form';
@import './librariesOverrides/antdOverrides/list';
@import './librariesOverrides/antdOverrides/dataDisplay';
@import './librariesOverrides/antdOverrides/datePicker';
@import './librariesOverrides/antdOverrides/table';
@import './librariesOverrides/swiperjs/fadeEdges';
@import './librariesOverrides/tiptap/tiptap.scss';

@import './utilities/typography';
@import './utilities/colors';
@import './utilities/sizes';
@import './utilities/buttons';

@import './general';
@import './responsive';