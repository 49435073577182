.ant-alert {
    border: none;
    border-radius: 0;

    &-error {
        background-color: $red50;
        color: $red800;

        .ant-alert-close-icon .anticon-close {
            color: $red400;

            &:hover {
                color: $red800;
            }
        }
    }
}

.ant-message {
    top: 1.7rem !important;

    .ant-message-notice {
        .ant-message-success {
            .anticon-check-circle {
                @apply text-green-400 me-3
            }
        }

        .ant-message-notice-content {
            border-radius: 0.375rem;
            @apply shadow-lg text-sm font-medium p-4;
        }

        &-success {

            .ant-message-notice-content {
                background-color: $green50;
                @apply text-green-800;
            }
        }

        &-error {
            .ant-message-notice-content {
                background-color: $red50;
                @apply text-red-800;
            }
        }
    }
}