@import '../../base/variables';

.ant-notification {
    .ant-notification-notice {
        background: linear-gradient(to right, $gradient3, $gradient4);
        padding: 0;
        width: 100%;
        text-align: center;
        margin-bottom: 0;

        &-content {
            div {
                .ant-notification-notice-description {

                    @apply text-white rounded-md text-sm leading-5 shadow-lg;
                }
            }
        }
    }
}

.ant-message-custom-content {
    display: flex;
}