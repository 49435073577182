[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[type="search"],
[multiple],
textarea,
.ant-input-number-input,
select {

    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }

}

.ant-input-number .ant-input-number-input,
.ant-input {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.editString textarea {
    border-color: transparent !important;
    border-bottom: 1px solid #DCDCE4 !important;
    border-radius: 0 !important;
}

.pr-0 {
    .ant-input-number-input {
        padding-right: 0;
    }
}

.number-handler-hidden {

    .ant-input-number-handler-wrap {
        display: none;
    }
}

.ant-input-prefix {
    font-size: xx-large !important;
    // padding-bottom: 1.2rem !important; // override search icon 
}

.text-base {

    .ant-select-selector,
    .ant-select-selection-search,
    .ant-select-selection-item {
        font-size: $font-base;
    }
}

// Form validation message/icon
.ant-form-item-explain-error>img {
    position: absolute;
    left: -1.8rem;
    top: 50%;
    transform: translateY(-50%);
}

.borderless-inputs .ant-form-item-explain-error {
    padding-left: 0.6rem;

    >img {
        left: -0.8rem;
    }
}

// Form large size edit
.ant-form-large .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content {

    .ant-input-affix-wrapper-lg,
    .ant-select-lg {
        min-height: $inputLg;
    }

    .ant-btn {
        min-height: calc($inputLg - 0.313rem);
    }
}

.form-inline {
    @apply flex flex-nowrap gap-5 w-full;

    .ant-btn {
        min-height: $inputLg !important;
    }
}

.ant-input-password-icon {
    cursor: pointer;
}

.ant-form-item {
    .ant-form-item-feedback-icon {
        font-size: $font-base;
    }
}

.ant-select,
.ant-select.ant-select-disabled {
    &.bg-transparent {
        .ant-select-selector {
            background-color: transparent;
        }
    }

    &.border-none {
        .ant-select-selector {
            border: none;
        }
    }
}

.ant-select-selection-item {
    @apply text-gray-600;
}

.ant-select.text-xs {
    .ant-select-selection-item {
        font-size: $font-xs;
    }
}

.ml-minus-11,
.m-minus-11 {
    margin-left: -0.75rem;
}

.ant-switch {
    background: rgba($black, .25);

    &.ant-switch-checked {

        &.bg-success {
            @apply bg-green-500;

            &:hover:not(.ant-switch-disabled) {
                @apply bg-green-600;
            }
        }

    }
}

.borderless {

    .ant-select .ant-select-selector,
    .ant-input-affix-wrapper {
        border-color: transparent;
        @apply shadow-sm;
    }
}

.ant-input-affix-wrapper-status-error {

    input::placeholder {
        @apply text-red-900;
    }
}

.ant-form-item-explain {
    margin: .5rem 0 1rem;
}

.block .ant-upload {
    display: block;
}

.w-full .ant-upload {
    width: 100%;
}

.upload-input-layout {
    @apply font-medium flex items-center border border-gray-300 rounded-md;

    &:hover,
    &:focus {
        @apply border-indigo-600;

        .upload-button {
            @apply text-indigo-600;
        }
    }

    .upload-text {
        @apply px-3 whitespace-nowrap overflow-hidden;
    }

    .upload-button {
        @apply border-s border-gray-300 h-full px-4 text-gray-700 text-sm flex items-center bg-gray-50 rounded-e-md;
    }
}

input[type="file"]:focus+.upload-input-layout {
    @apply border-indigo-600;

    .upload-button {
        @apply text-indigo-600;
    }
}


.ant-form-large {
    .upload-input-layout {
        height: $inputLg;
    }
}
.domain .ant-form-item-explain-error {
    padding-left: 3.7rem;
    font-size: small;
}
.input-gray-color .ant-input {
    @apply text-sm leading-5 font-normal caret-gray-500 text-gray-500;
}