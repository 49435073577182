@import '../../../../../shared/scss/base/variables';

.mentions-input {
    @apply bg-white rounded-md flex-grow;
    max-height: $commentInputHeight;
    padding: $commentInputPad 4rem $commentInputPad $commentInputPad;

    &__control {
        @apply relative overflow-hidden;
        max-height: ($commentInputHeight - $commentInputPad * 2);

    }

    &__input {
        @apply font-normal leading-5 border-none p-0 pr-2;
        font-size: $font-sm !important;
        max-height: $commentInputHeight;
        overflow-y: auto !important;
    }

    &__highlighter {
        @apply font-normal leading-5 pr-2 pointer-events-none;
        border: none !important;
        font-size: $font-sm !important;
        max-height: ($commentInputHeight - $commentInputPad * 2);
        overflow-wrap: anywhere !important;
        overflow-y: auto !important;
    }
    
    &__suggestions {
        @apply rounded-xl p-2 ring-1 ring-black ring-opacity-5 shadow-lg;
        max-height: 30rem;
        overflow-y: auto;
        z-index: 10 !important;
    }
}