.reportsDatePicker {
    background: transparent;
    border: none;

    .ant-picker-input {
        input {
            text-align: center;
        }
    }
}

.reportsDateRangePicker {

    .ant-picker-panel:nth-child(2) {
        display: none;
    }

    .ant-picker-panel:nth-child(1) button {
        visibility: visible !important;
    }

    &.ant-picker-dropdown .ant-picker-panel-container {
        .ant-picker-header {
            border-bottom: none;
            @apply p-4;

            button {
                @apply text-base font-semibold;
            }

            .ant-picker-header-view {
                @apply py-0 px-2 flex;
            }

            .ant-picker-header-super-prev-btn {
                order: 1;
            }

            .ant-picker-header-prev-btn {
                order: 2;
            }

            .ant-picker-header-next-btn {
                order: 3;
            }

            .ant-picker-header-super-next-btn {
                order: 4;
            }

        }

        .ant-picker-body {
            @apply pt-5 px-2 pb-2;

            .ant-picker-content {
                width: 100%;
            }

            tr th {
                @apply pb-3;
            }

            .ant-picker-cell {
                padding: 0;
                @apply h-9;

                &:before {
                    @apply min-w-9 h-9 left-0 py-1.5 px-1;
                }

                .ant-picker-cell-inner {
                    @apply text-center leading-7 h-7 text-base min-w-7;
                }

                &.ant-picker-cell-range-hover-start,
                &.ant-picker-cell-range-hover-end {

                    .ant-picker-cell-inner {
                        @apply rounded-full;
                    }
                }
            }

            .ant-picker-cell-range-start,
            .ant-picker-cell-range-end {
                .ant-picker-cell-inner {
                    @apply font-semibold bg-indigo-500 rounded-full;
                }
            }

            .ant-picker-cell-range-start,
            .ant-picker-cell-range-end,
            .ant-picker-cell-in-range {
                &:before {
                    @apply bg-indigo-50;
                }
            }

            .ant-picker-cell-range-start:before {
                @apply rounded-s-full;
            }

            .ant-picker-cell-range-end:before {
                @apply rounded-e-full;
            }
        }

        .ant-picker-presets ul {
            border: none;

            li {
                margin: 0;
                @apply py-2 px-4;

                &:hover {
                    @apply bg-indigo-50;
                }
            }
        }

    }
}