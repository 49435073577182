[class*='btn-'] {
    @apply font-medium;
}



// Button styles
.btn-primary {
    @apply bg-indigo-600 text-white;

    &:not(:disabled):not(.ant-btn-disabled):hover {
        @apply bg-indigo-700 text-white;
    }

    &:disabled {
        @apply bg-indigo-200 border-indigo-200 text-white;
    }
}

.btn-secondary {
    @apply bg-white text-gray-500;
}

.btn-primary-light {
    @apply bg-indigo-50 border-indigo-50 text-indigo-700;

    &:not(:disabled):not(.ant-btn-disabled) {

        &:hover,
        &:focus {
            @apply text-indigo-700;
        }
    }

    &:disabled {
        @apply bg-white border-gray-300 text-gray-300;
    }
}

.btn-danger {
    @apply bg-red-600 border-red-600 text-white;

    &:not(:disabled):not(.ant-btn-disabled) {

        &:hover,
        &:focus {
            @apply bg-red-700 text-gray-100;
        }
    }

    &:disabled {
        @apply bg-red-200 border-red-200 text-white;
    }
}

.btn-link {
    @apply bg-transparent border-none text-indigo-700 shadow-none;

    &:not(:disabled):not(.ant-btn-disabled) {

        &:hover,
        &:focus {
            @apply text-indigo-800;
        }
    }

    &:disabled {
        @apply text-gray-300 bg-transparent border-none;
    }
}

// Button sizes
.btn-sm,
.ant-btn.btn-sm {

    &,
    .ant-select-selector {
        height: $btnSm;
        min-height: $btnSm !important;
    }
}

.btn-md,
.ant-btn.btn-md {

    &,
    .ant-select-selector {
        height: $btnMd;
        min-height: $btnMd !important;
    }
}

.sm\:btn-md,
.ant-btn.sm\:btn-md {
    @include smBreakpoint {

        &,
        .ant-select-selector {
            height: $btnMd;
            min-height: $btnMd !important;
        }
    }
}

.btn-lg,
.ant-btn.btn-lg {

    &,
    .ant-select-selector {
        height: $btnLg;
        min-height: $btnLg !important;
    }
}

// Button icon styles
.btn-icon {
    margin-top: -0.125rem;
}

// Select button styles
.ant-select[class*="select-btn-"] {
    .ant-select-selector {
        padding: 0.25rem 0.9375rem
    }

    .ant-select-selection-item {
        @apply font-medium;
    }
}

.ant-select.text-mobile-hidden {
    .ant-select-selection-item {
        @apply hidden md:inline;
    }
}

.ant-select-item-option-content>* {

    &::first-letter {
        @apply uppercase;
    }
}

.ant-select.select-btn-primary-light {
    .ant-select-selector {
        @apply bg-indigo-50 border-indigo-50 shadow-sm;

        .ant-select-selection-item {
            @apply text-indigo-700 capitalize;
        }
    }

    &.ant-select-disabled {
        .ant-select-selector {
            @apply bg-white border-gray-300;

            .ant-select-selection-item {
                @apply text-gray-300;
            }
        }
    }
}

.ant-select-dropdown {
    // width: 12rem !important;
    min-width: fit-content;
}

.bg-transparent {
    &.ant-select.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
        background: transparent;
    }
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:not(.ant-dropdown-menu-item-disabled):hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: rgb(224, 36, 36, 1)
}

//cndidates profile page
@layer utilities {
    .text-center-webkit {
        text-align: -webkit-center;
    }
}

.relative {
    .rc-virtual-list-holder {
        div {
            .rc-virtual-list-holder-inner {
                position: relative !important;
            }
        }
    }
}