.wrapper {
    max-width: $contentWidth;
    margin-right: auto;
    margin-left: auto;

    &-sm {
        max-width: $contentWidthSm;
    }

    &-md {
        max-width: $contentWidthMd;
    }

    &-lg {
        max-width: $contentWidthLg;
    }

    &-xl {
        max-width: $contentWidthXl;
    }
}

.h-nav {
    height: $navHeight;
}

.h-header {
    height: auto;

    @include mdBreakpoint {
        height: $headerHeight;
    }
}

.top-nav {
    top: $navHeight;
}

.top-header {
    top: $headerHeight;
}

.top-nav-header {
    top: $navHeight + $headerHeight;
}

.h-screen-minus-nav {
    height: calc(100vh - $navHeight);
}

.h-screen-minus-nav-header {
    height: calc(100vh - $navHeight - $headerHeight);
}

.min-h-screen-minus-nav-header {
    min-height: calc(100vh - $navHeight - $headerHeight);
}

.px-1-i {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
}