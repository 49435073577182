.Typewriter__cursor {
    animation: flash 1s infinite linear;
    color: $indigo600;
    display: inline-flex;
    align-items: center;
    height: 4rem;
    overflow: hidden;
}

@keyframes flash {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}