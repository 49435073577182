@import '../../scss/base/variables';

.loader {
  height: 0.25rem;
  border-radius: 1.25rem;
  width: 100%;
  background: linear-gradient(to right, $gradient1, $gradient1 40%, $gradient2, $gradient3 60%, $gradient3);
  background-size: 200%;
  background-position: center;
  animation: loader 1.5s alternate-reverse infinite linear;
  transition: opacity .2s ease-in-out;
}

@keyframes loader {
  0% { background-position: 0%;}
  100% { background-position: 100%;}
}