.text-radial-gradient-primary {
    background: radial-gradient(ellipse at bottom, $gradient2, $gradient1);
    background-clip: text;
    color: transparent;
}

mark {
    @apply bg-indigo-50 rounded-md inline-block text-base px-[7px] py-0.5 text-gray-600;
}

.tiptap,
.markdown {
    font-size: 1rem;

    p:not(li > p):not(:first-child) {
        margin-top: .75rem;
        line-height: 1.714;
    }

    ul,
    ol {
        margin-top: 0.625rem;
    }
}