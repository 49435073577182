.floatEffect {
    animation: floatEffect 10s infinite backwards linear;
}

.floatEffect2 {
    animation: floatEffect2 7s infinite backwards linear;
}

.floatEffect2a {
    animation: floatEffect2 5s infinite backwards linear;
}

.floatEffect3 {
    animation: floatEffect3 6s infinite backwards linear;
}

.floatEffect4 {
    animation: floatEffect4 3s infinite backwards linear;
}

.floatEffect5 {
    animation: floatEffect5 3s infinite backwards linear;
}

@keyframes floatEffect {

    0%,
    100% {
        transform: translate3d(0rem, -0.2rem, 0);
    }

    25% {
        transform: translate3d(0.2rem, 0, 0);
    }

    50% {
        transform: translate3d(0.2rem, -0.3rem, 0);
    }

    75% {
        transform: translate3d(0.1rem, 0.2rem, 0);
    }
}

@keyframes floatEffect2 {

    0%,
    100% {
        transform: translate3d(-0.3rem, 0, 0);
    }

    25% {
        transform: translate3d(0.1rem, 0.4rem, 0);
    }

    50% {
        transform: translate3d(-0.4rem, 0.1rem, 0);
    }

    75% {
        transform: translate3d(0, -0.2rem, 0);
    }
}

@keyframes floatEffect3 {

    0%,
    100% {
        transform: translate3d(-0.3rem, 0.3rem, 0);
    }

    25% {
        transform: translate3d(0.2rem, 0.1rem, 0);
    }

    50% {
        transform: translate3d(0.4rem, 0.3rem, 0);
    }

    75% {
        transform: translate3d(-0.1rem, -0.2rem, 0);
    }
}

@keyframes floatEffect4 {

    0%,
    100% {
        transform: translate3d(0, 0.3rem, 0);
    }

    25% {
        transform: translate3d(0, 0.1rem, 0);
    }

    50% {
        transform: translate3d(0, 0, 0);
    }

    75% {
        transform: translate3d(0.1rem, 0.2rem, 0);
    }
}

@keyframes floatEffect5 {

    0%,
    100% {
        transform: translate3d(0, 0, 0);
    }

    25% {
        transform: translate3d(0.1rem, -0.1rem, 0);
    }

    50% {
        transform: translate3d(0.2rem, 0, 0);
    }

    75% {
        transform: translate3d(0.1rem, 0.1rem, 0);
    }
}

.cursor-blink {
    animation: cursor-blink 1s steps(2) infinite;
}

@keyframes cursor-blink {
    0% {
        opacity: 0;
    }
}