.ant-spin-container {
    .ant-table {
        @apply rounded-[0.875rem] p-5
    }
}

.ant-table-thead {
    tr {
        th.ant-table-cell {
            @apply bg-white text-gray-400 text-xs leading-4 font-medium border-b border-b-gray-300
        }

        td.ant-table-cell {
            @apply bg-white
        }
    }
}

.ant-table-tbody {
    tr {
        td.ant-table-cell {
            @apply text-sm leading-5 text-gray-600 py-3 px-4 border-b border-b-gray-200
        }
    }

    tr>:first-child {
        @apply font-medium
    }

    tr:last-child {
        td {
            @apply border-b-0
        }
    }
}

.ant-dropdown {
    .ant-table-filter-dropdown {
        @apply rounded-xl
    }
}

.candidatesTable .ant-table-thead {
    .ant-table-column-sorters {
        min-width: max-content;
    }

    .ant-table-column-title {
        white-space: nowrap;
    }
}

.candidatesTable td.ant-table-cell:nth-child(4) {
    min-width: 8.125rem;
}

.candidatesTable td.ant-table-cell:nth-child(6) {
    min-width: 22rem;
}

.candidatesTable td.ant-table-cell:not(:nth-child(6)) {
    white-space: nowrap;
}