.fade-start,
.fade-end {
    position: absolute;
    top: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4.125rem;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.95), transparent);
    z-index: 2;
    transition: opacity .2s ease-in-out;
    pointer-events: none;
}

.fade-end {
    background: linear-gradient(to left, rgba(255, 255, 255, 0.95), transparent);
    left: unset;
    right: 0;
}

.fade-start-gray {
    background: linear-gradient(to right, $light100, transparent);
}

.fade-end-gray {
    background: linear-gradient(to left, $light100, transparent);
}

.swiper-wrapper {

    &:has(:first-child.swiper-slide-fully-visible) {
        ~.fade-start {
            opacity: 0;
        }
    }

    &:has(:last-child.swiper-slide-visible) {
        ~.fade-end {
            opacity: 0;
        }
    }
}