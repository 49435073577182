// Color palette
$white: #FFFFFF;
$black: #000000;

$light100: #F6F6F9;
$light200: #DCDCE4;

$neutral200: #DCDCE4;
$neutral500: #8E8EA9;

$indigo50: #EEF2FF;
$indigo100: #E0E7FF;
$indigo200: #C7D2FE;
$indigo500: #6366F1;
$indigo600: #4F46E5;
$indigo700: #4338CA;

$gray50: #F9FAFB;
$gray300: #D1D5DB;
$gray400: #9CA3AF;
$gray500: #6B7280;
$gray600: #4B5563;
$gray800: #1F2937;

$red50: #FEF2F2;
$red400: #F87171;
$red800: #991B1B;

$green50: #ECFDF5;
$green100: #D1FAE5;
$green700: #047857;

$gradient1: #4945FF;
$gradient2: #AA54FF;
$gradient3: #5145FF;
$gradient4: #8B54FF;

// Text color

// Background color

// Border color

// Shadow size

// Shadow color

// Shadows

// Spacing
$pagination_p_y: 0.4rem;
$pagination_p_x: .75rem;
$prevNext_m_sm: 1rem;
$prevNext_m_md: 1.875rem;

$commentInputPad: 0.625rem;

// Sizes
$contentWidth: 52.5rem;
$contentWidthSm: 26.25rem;
$contentWidthMd: 38.875rem;
$contentWidthLg: 48.125rem;
$contentWidthXl: 63.125rem;

$commentInputHeight: 6.25rem;

$btnSm: 1.5rem;
$btnMd: 2.375rem;
$btnLg: 3.125rem;

$navHeight: 4rem;
$headerHeight: 4.5rem;

$inputLg: 3.438rem;

// Font sizes
$font-base: 1rem;
$font-sm: 0.875rem;
$font-xs: 0.75rem;

// Typography

// Helpers

// Animations
$smooth: cubic-bezier(0.5, 0.35, 0.15, 1);

// Breakpoints
// Min width
$min-screen-sm: 576px;
$min-screen-md: 768px;
$min-screen-lg: 992px;
$min-screen-xl: 1200px;
$min-screen-2x: 1366px;
$min-screen-3x: 1601px;
$min-screen-4x: 1921px;

// Max width
$max-screen-sm: 767px;
$max-screen-md: 991px;
$max-screen-lg: 1199px;
$max-screen-xl: 1365px;
$max-screen-2x: 1600px;
$max-screen-3x: 1920px;
$max-screen-4x: 2800px;

// Mobile breakpoints
$max-mobile-xs: 479px;
$max-mobile-mini: 575px;
$max-mobile-sm: 580px;